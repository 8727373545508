import Loadable from 'react-loadable'; 

const lazyLoadRoutes = (componentName) => {
    const LazyComponent = Loadable({
        loader: () => import(`../pages/${componentName}`),
        loading: () => <div>Loading...</div>
    })

    return LazyComponent;
}

export default lazyLoadRoutes