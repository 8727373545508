import React from 'react'
import { Alert, Button, Divider, Space, Typography } from 'antd'
import SignInButton from './SignInButton'
import { NavLink } from 'react-router-dom';

const { Title } = Typography;

const AccessDenied = () => {
    return (
        <>
            {/* <Button variant="secondary">Request Profile Information</Button>
            <h5 className="card-title">Please sign-in to see your profile information.</h5> */}

            <div className="layout">
                <div className='main'>
                    <div className="topbar">
                        <div className="topbarWrapper">
                            <div className="topLeft">
                                <span className='logo' style={{ color: 'red', marginRight: 5 }}>Cheonan </span> <span> HR T&A</span>

                            </div>
                            <div className="topRight">
                                <img src="https://www.dupont.com/content/dam/dupont/global-assets/logos/DuPont_logo_140x55_rgb1.png" className="topAvatar" />
                            </div>
                        </div>
                    </div>
                    <div className="layout__content">
                        <div className="layout__content-main">
                            <div style={{ marginTop: '15%', marginLeft: '20%', width: '40%' }}>
                                <div style={{ lineHeight: 2 }}>
                                    <Title level={2}>403 - Access Denied</Title>

                                    <p>You do not have permission to access <strong>Cheonan</strong> portal.</p>
                                    <p>Please contact site admin to request access.</p>
                                    <div style={{ marginLeft: '70%', marginTop: 30 }}>
                                        <NavLink to="/NeedHelp">
                                            <Button type='primary'>Request Access</Button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccessDenied