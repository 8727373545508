const initialState = {}

const DailyShiftReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_HISTORY":
            return action.payload;
        case "CREATE_SHIFT":
            return [action.payload, ...state];
        case "UPDATE_SHIFT":
            const newData = action.payload.data;
            const HRSeq = action.payload.HRSeq;
            
            const index = state.findIndex(x => x.HRSeq === HRSeq);          

            state[index] = {
                HRSeq: HRSeq,
                EmployeeID: newData.EmployeeID,
                HRDate: newData.HRDate,
                StartDatetime: newData.StartDatetime,
                EndDatetime: newData.EndDatetime,
                lunchhour: newData.lunchhour,
                ShiftType: newData.ShiftType,
                Sethours: newData.Sethours,
                SetRestMinutes: newData.SetRestMinutes
            }

            return state;

        case "DELETE_SHIFT":
            const history = state.filter(x => x.HRSeq !== action.payload);
            return history;

        default:
            return state;
    }
}


export default DailyShiftReducer