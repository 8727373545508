const initialState = {}

const wfhReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_HISTORY":
            return action.payload;

        case "CREATE":
            return [action.payload, ...state];

        case "UPDATE":
            const newData = action.payload.data;

            const index = state.findIndex(x => x.WorkSeq === action.payload.workSeq);

            state[index] = {
                WorkSeq: action.payload.workSeq,
                EmployeeID: newData.EmployeeID,
                TypeID: newData.TypeID,
                Starttime: newData.Starttime,
                Endtime: newData.Endtime,
                Justification: newData.Justification,
                Note: newData.Note
            }

            return state;

        case "DELETE":
            const history = state.filter(x => x.WorkSeq !== action.payload);
            return history;
        default:
            return state;
    }
}

export default wfhReducer