import React, { useRef } from 'react'
import { Image } from 'antd'
import moment from 'moment'

const NeedHelp = () => {
    return (
        <div>
            {/* <h2 className="page-header">Need Help</h2> <br /> */}
            <br />
            <h3>Welcome to the Cheonan HR Portal Support!</h3>

            <div style={{ lineHeight: 1.7, marginTop: 20, width: '95%',  }}>
                <p>
                    We're here to help. Check our Frequently Asked Questions (FAQ) to get quick answers to the most popular topics.
                    If you can't find an answer to your question there, contact a system administrator below for further inquiries.
                </p>

                <div style={{ marginTop: 20 }}>
                    Please contact our system administrators if you require any assistance while using the Chonan HR System tool.
                </div>

                <div style={{ marginTop: 20 }}>
                    Our system administrators local times are listed for your convenience.
                </div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 20, padding: 10 }}>
                <div>
                    <Image
                        width={170}
                        height={170}
                        src="../assets/emptyimage.png"
                        preview={false}
                    />
                </div>
                <div style={{ margin: 15, lineHeight: 2 }}>
                    <p><strong>Name</strong> : Jung, Ricky</p>
                    <p><strong>Email</strong> : ricky.jung@dupont.com</p>
                    <p><strong>Location</strong> : AP-KR-Cheonan1</p>
                    <p><strong>Local Time</strong> : {moment().format('lll')}</p>
                </div>
            </div> 
        </div>
    )
}

export default NeedHelp