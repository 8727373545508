const initialState = {}

const teamPermissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TEAMPERMISSIONS":
            return action.payload;

        case "CREATE_TEAMPERMISSION":
            let res = [];
            for (var i = 0; i <= action.payload.TeamID.length - 1; i++) {
                res = [...res, {
                    TeamID: action.payload.TeamID[i],
                    EmployeeID: action.payload.EmployeeID
                }]
            }

            return [...res, ...state];

        case "UPDATE_TEAMPERMISSION":
            const originalState = [...state];
            const index = originalState.findIndex(x => x.TeamID === action.payload.TeamID);

            originalState[index] = {
                TeamID: action.payload.TeamID,
                TeamName: action.payload.TeamName,
                ManagerID: action.payload.ManagerID,
                UseYN: action.payload.UseYN
            }

            return originalState;

        case "DELETE_TEAMPERMISSION":
            const history = state.filter(x => x.EmployeeID !== action.payload.EmployeeID);

            const employee = state.filter(x => x.EmployeeID === action.payload.EmployeeID
                && x.TeamID !== action.payload.TeamID
            );

            const modifiedObject = [...employee, ...history];
            return modifiedObject;
        default:
            return state;
    }
}

export default teamPermissionReducer