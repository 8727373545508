import { useEffect } from 'react' 
import { useMsal } from '@azure/msal-react'; 
import { loginRequest } from '../config/msal/authConfig'; 
import { useLocation } from 'react-router-dom';
import Notify from '../components/Notify';

const ProtectedRoute = ({ children }) => {
  const { instance, accounts } = useMsal();  
  const location = useLocation();
  
  useEffect(() => {
    const checkAuthStatus = async () => {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      try {
        await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        }).then((response) => {
        });
      } catch (error) { 
        Notify('error', 'Error', `Your session has expired. You will be redirected to the login page.`)
        window.location.reload();        
      }
    }
    checkAuthStatus();
  }, [location])

  return children;
};

export default ProtectedRoute