import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "./config/msal/graph";
import { loginRequest } from "./config/msal/authConfig";

import { useSelector, useDispatch } from 'react-redux';
import { setUserProfile } from "./redux/actions/authActions";
import { getCommonAPI } from "./redux/actions/commonActions";

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Login from "./components/Login";

import AppRoutes from "./route/AppRoutes";
import './App.css';
import { axiosClient } from "./config/axiosConfig";
import AccessDenied from "./components/AccessDenied";
import NeedHelp from "./pages/NeedHelp"

function App() {
  const [accessToken, setAccessToken] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [hasAccess, setHasAccess] = useState(true);
  const [authInProgress, setAuthInProgress] = useState(false);
  const {
    auth: {
      isAuthenticated,
      user
    }
  } = useSelector(state => state);

  const dispatch = useDispatch();
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    setAuthInProgress(true);
    const getUserProfile = async () => {
      try {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        }).then((response) => {
          setAccessToken(response.accessToken);
          callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
      } catch (error) {
        setAuthInProgress(false);
      }
    }
    getUserProfile();
  }, [instance, inProgress, accounts])

  useEffect(() => {
    const checkUserAccess = async () => {
      try {
        
      } catch (error) {
        
      }
      if (graphData) {
        const { displayName, userPrincipalName, givenName, surname } = graphData;

        const users = await axiosClient.get('/users', {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });


        if (users.length > 0) {
          const user = users.find(x => x.Email === userPrincipalName)
          const userProfile = {
            isAuthenticated: true,
            user: {
              id: user.EmployeeID,
              fullname: displayName,
              email: userPrincipalName,
              firstname: givenName,
              lastname: surname
            },
            role: users.find(x => x.Email === userPrincipalName).PermissionID,
            token: accessToken
          }
          dispatch(setUserProfile(userProfile))
        }
        else {
          setHasAccess(false)
        } 
      }
    }
    checkUserAccess();
  }, [graphData]);

  useEffect(() => { 
    if (isAuthenticated) {
      dispatch(getCommonAPI());
    }
    setAuthInProgress(false);
  }, [isAuthenticated]);


  return (
    <>
      {!authInProgress && <Router>
        {!isAuthenticated && <Login />}

        {isAuthenticated && !hasAccess && <AccessDenied />}

        {isAuthenticated && hasAccess &&
          <div className="layout">
            <Sidebar />
            <div className='main'>
              <div className="layout__content">
                <Header displayName={user.fullname} />
                <div className="layout__content-main ">
                  <AppRoutes />
                </div>
              </div>
            </div>
          </div>
        }
      </Router>
      }

      {authInProgress && <div style={{ position: 'absolute', top: '45%', left: '35%', fontSize:20}}>
        <h4>
          Just a moment, we are getting things ready for you...
        </h4>
      </div>}
    </>
  );
}

export default App; 