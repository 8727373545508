import { axiosClient } from "../../config/axiosConfig"

export const Team_TYPES = {
    GET_TEAMS: 'GET_TEAMS',
    CREATE_TEAM: 'CREATE_TEAM',
    UPDATE_TEAM: 'UPDATE_TEAM',
    DELETE_TEAM: 'DELETE_TEAM'
}

export const getTeams = () => async (dispatch) => {
    try {
        const response = await axiosClient.get(`/teammaster`);
        dispatch({
            type: Team_TYPES.GET_TEAMS,
            payload: response
        })
    } catch (err) {

    }
}

export const createTeam = (data) => async (dispatch) => {
    await axiosClient.post('/teammaster/', data).then(response => {
        dispatch({
            type: Team_TYPES.CREATE_TEAM,
            payload: response.TeamMaster
        })
        return response;
    })
}

export const updateTeam = (data) => async (dispatch) => {
    await axiosClient.patch(`/teammaster/${data.TeamID}`, data).then(response => {
        dispatch({
            type: Team_TYPES.UPDATE_TEAM,
            payload: data
        })
    })
}

export const deleteTeam = (data) => async (dispatch) => {
    await axiosClient.delete(`/teammaster/${data}`).then(response => {
        dispatch({
            type: Team_TYPES.DELETE_TEAM,
            payload: data
        })
    })
}