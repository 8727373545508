import React from 'react'
import { Alert, Button, Divider, Typography } from 'antd'
import SignInButton from './SignInButton'

const { Title } = Typography;

const Login = () => {
    return (
        <>
            {/* <Button variant="secondary">Request Profile Information</Button>
            <h5 className="card-title">Please sign-in to see your profile information.</h5> */}

            <div className="layout">
                <div className='main'>
                    <div className="topbar">
                        <div className="topbarWrapper">
                            <div className="topLeft">
                                <span className='logo' style={{ color: 'red', marginRight: 5 }}>Cheonan </span> <span> HR T&A</span>

                            </div>
                            <div className="topRight">
                                <img src="https://www.dupont.com/content/dam/dupont/global-assets/logos/DuPont_logo_140x55_rgb1.png" className="topAvatar" />
                            </div>
                        </div>
                    </div>
                    <div className="layout__content">
                        <div className="layout__content-main">
                            <div style={{ marginTop: '15%', marginLeft: '15%', width: '60%' }}>
                            <div style={{lineHeight:2}}>
                                <Title level={2}>401 - Unauthorized</Title>
                                
                                <p>We couldn't validate your credentials. This page is not publically available.</p>
                                
                                <p>To access it please click on signIn button to login.</p>
                                <div style={{float:'right', marginTop:20, marginRight:'20%'}}>
                                    <SignInButton />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login