import { getTeams } from "./teamActions" 
import { getUsers } from "./userActions";

export const getCommonAPI = () => async (dispatch) => { 
    try { 
       await dispatch(getTeams());
       await dispatch(getUsers());    
    } catch (err) {
         
    }
}