
export const Auth_TYPES = {
    USER_PROFILE: 'USER_PROFILE'
} 

export const setUserProfile = (data) => async (dispatch) => { 
    try { 
        dispatch({ 
            type: Auth_TYPES.USER_PROFILE, 
            payload: data
        })  
        
    } catch (err) {
         
    }
}