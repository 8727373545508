const initialState = {}

const holidayReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_HOLIDAYS":
      return action.payload;
    case "CREATE_HOLIDAY":
      return [...state, action.payload];
    case "UPDATE_HOLIDAY":
      const originalState = [...state];
      const index = originalState.findIndex(x => x.HRDate === action.payload.HRDate);

      originalState[index] = {
        HRDate: action.payload.HRDate,
        HolidayName: action.payload.HolidayName,
        HolidayDay: action.payload.HolidayDay
      }

      return originalState;
    case "DELETE_HOLIDAY":
      const holidayList = state.filter(x => x.HRDate !== action.payload)
      return holidayList
    default:
      return state;
  }
}


export default holidayReducer