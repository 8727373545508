import { axiosClient } from "../../config/axiosConfig";

export const User_TYPES = {
    GET_USERS: 'GET_USERS',
    REGISTER_USER: 'REGISTER_USER',
    UPDATE_USER:'UPDATE_USER',
    DELETE_USER:'DELETE_USER'
}

export const getUsers = () => async (dispatch) => {
    try {
        const response = await axiosClient.get('/users');
        dispatch({
            type: User_TYPES.GET_USERS,
            payload: response
        })

    } catch (err) {

    }
}

export const rigisterUser = (data) => async (dispatch) => {
    try {
        await axiosClient.post('/users/', data);
        dispatch({
            type: User_TYPES.REGISTER_USER,
            payload: data
        })

    } catch (err) {

    }
}

export const updateUser = (data) => async (dispatch) => {
    try {
        await axiosClient.patch(`/users/${data.EmployeeID}/${data.CardNo}`, data).then(response => {
            dispatch({
                type: User_TYPES.UPDATE_USER,
                payload: data
            })
        })

    } catch (err) {

    }
}

export const deleteUser = (data) => async (dispatch) => {
    try {
        await axiosClient.delete(`/users/${data.EmployeeID}/${data.CardNo}`).then(response => {
            dispatch({
                type: User_TYPES.DELETE_USER,
                payload: data
            })
        })
    } catch (err) {

    }
}