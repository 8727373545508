import React from 'react'
import { useMsal } from "@azure/msal-react";
import { Button } from 'antd';

const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <> 
            <i className="bx bx-log-out" onClick={() => handleLogout("popup")}>
                <span>Logout</span>
            </i>
            {/* <Button variant="secondary" onClick={() => handleLogout("popup")}>SignOut using Popup</Button>
            <Button variant="secondary" onClick={() => handleLogout("redirect")}>SignOut using Redirect</Button> */}
        </>
    )
}

export default SignOutButton