import React from 'react'
import { notification } from 'antd'

const Notify = (type, title, message) => {
    const getNotificationStyle = type => {
        return {
            success: {
                color: 'rgba(0, 0, 0, 0.65)',
                border: '1px solid #b7eb8f',
                backgroundColor: '#f6ffed',
                marginTop:37
            },
            warning: {
                color: 'rgba(0, 0, 0, 0.65)',
                border: '1px solid #ffe58f',
                backgroundColor: '#fffbe6',
                marginTop:37
            },
            error: {
                color: 'rgba(0, 0, 0, 0.65)',
                // border: '1px solid #ffa39e',
                backgroundColor: '#fff1f0',
                marginTop:37
            },             
            info: {
                color: 'rgba(0, 0, 0, 0.65)',
                border: '1px solid #91d5ff',
                backgroundColor: '#e6f7ff',
                marginTop:37
            }
        }[type]
    }

    return (
        notification[type]({
            message: title,
            description: <div style={{ height: 45 }}>{message}</div>,
            style: getNotificationStyle(type)
        })
    )
}

export default Notify