import React from 'react'
import { Route, Routes } from 'react-router-dom' 
import ProtectedRoute from './ProtectedRoute'
import lazyLoadRoutes from '../components/LazyLoadRoutes';
import Loadable from 'react-loadable';  

const Dashboard = lazyLoadRoutes('Dashboard');
const Holidays = lazyLoadRoutes('Holidays');
const Register = lazyLoadRoutes('Register');
const DailyShift = lazyLoadRoutes('DailyShift');
// const MonthlyData = lazyLoadRoutes('MonthlyData');
const TeamPermission = lazyLoadRoutes('TeamPermission');
const TeamMaster = lazyLoadRoutes('TeamMaster');
const Users = lazyLoadRoutes('Users');
const WorkFromHome = lazyLoadRoutes('WorkFromHome');
const UploadFile = lazyLoadRoutes('UploadFile');
const NeedHelp = lazyLoadRoutes('NeedHelp')
const NotFound = lazyLoadRoutes('NotFound');

const MonthlyData = Loadable({
    loader: () => import('../pages/MonthlyData/index.js'),
    loading: () => <div>Loading...</div>
});

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path='/' element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
                <Route path='/Dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            </Route>
            <Route exact path={'/Holidays'} element={<ProtectedRoute><Holidays /></ProtectedRoute>} /> 
            <Route exact path={'/Register'} element={<ProtectedRoute><Register /></ProtectedRoute>} />
            <Route exact path={'/DailyShift'} element={<ProtectedRoute><DailyShift /></ProtectedRoute>} />
            <Route exact path={'/MonthlyData'} element={<ProtectedRoute><MonthlyData /></ProtectedRoute>} />
            <Route exact path={'/TeamPermission'} element={<ProtectedRoute><TeamPermission /></ProtectedRoute>} />
            <Route exact path={'/TeamMaster'} element={<ProtectedRoute><TeamMaster /></ProtectedRoute>} />            
            <Route exact path={'/Users'} element={<ProtectedRoute><Users /></ProtectedRoute>} />
            <Route exact path={'/WorkFromHome'} element={<ProtectedRoute><WorkFromHome /></ProtectedRoute>} />
            <Route exact path={'/UploadFile'} element={<ProtectedRoute><UploadFile /></ProtectedRoute>} />
            <Route exact path={'/NeedHelp'} element={<NeedHelp />} />
            <Route exact path={'*'} element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
        </Routes>
    )
}

export default AppRoutes