import { combineReducers } from 'redux'
import auth from './authReducer'
import teams from './teamReducer'
import users from './userReducer'
import holidays from './holidayReducer'
import workFromHome from './wfhReducer'
import dailyShift from './dailyShiftReducer'
import teamPermission from './teamPermissionReducer'

export default combineReducers({
    auth,
    teams,
    holidays,
    users,
    workFromHome,
    dailyShift,
    teamPermission
})