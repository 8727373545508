import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    document.querySelectorAll('.sidebar-submenu').forEach(e => {
      e.querySelector('.sidebar-menu-dropdown').onclick = (event) => {
        event.preventDefault()
        e.querySelector('.sidebar-menu-dropdown .dropdown-icon').classList.toggle('active')

        let dropdown_content = e.querySelector('.sidebar-menu-dropdown-content')
        let dropdown_content_lis = dropdown_content.querySelectorAll('li')

        let active_height = dropdown_content_lis[0].clientHeight * dropdown_content_lis.length

        dropdown_content.classList.toggle('active')

        dropdown_content.style.height = dropdown_content.classList.contains('active') ? active_height + 'px' : '0'
      }
    })
  }, []);

  return (
    <>
      <div className="sidebar">
        <div className="sidebar__logo">
          <span style={{ color: 'red', marginRight: 5 }}>Cheonan </span> <span> HR T&A</span>
        </div>
        <ul className="sidebar-menu scrollview">
          <li style={{ fontSize: 13, fontWeight: 600, color: '#4680ff', padding: '18px 15px 5px', textTransform: 'capitalize', position: 'relative' }}>
            <h3 style={{ color: '#8391a2' }}>HRS</h3>
          </li>
          <li>
            <NavLink to="/">
              <i className="bx bx-home" />
              <span>Dashboard</span>
            </NavLink>
          </li>


          <li style={{ fontSize: 13, fontWeight: 600, color: '#4680ff', padding: '18px 15px 5px', textTransform: 'capitalize', position: 'relative' }}>
            <h3 style={{ color: '#8391a2' }}>
              {auth.role === "A" && 'Admin'}
              {auth.role === "T" && 'Manager'}
              {auth.role === "U" && 'User'}
            </h3>
          </li>
          {(auth.role === "U" || auth.role === "T") && <>
            <li>
              <NavLink to="/MonthlyData">
                <i className="bx bx-calendar" />
                <span>Monthly Data</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/WorkFromHome">
                <i className="bx bxs-watch" />
                <span>Work From Home</span>
              </NavLink>
            </li>
          </>
          }
          <li>
            <NavLink to="/DailyShift">
              <i className="bx bx-time-five" />
              <span>Daily Shift Master</span>
            </NavLink>
          </li>
          {auth.role === "A" && <>
            <li>
              <NavLink to="/Holidays">
                <i className="bx bx-chart" />
                <span>Holiday Master</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Register">
                <i className="bx bx-user-circle" />
                <span>User Master</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/MonthlyData">
                <i className="bx bx-calendar" />
                <span>Monthly Data</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/WorkFromHome">
                <i className="bx bxs-watch" />
                <span>Work From Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/UploadFile">
                <i className="bx bxs-cloud-upload" />
                <span>Upload File</span>
              </NavLink>
            </li>

            <li style={{ fontSize: 13, fontWeight: 600, color: '#4680ff', padding: '18px 15px 5px', textTransform: 'capitalize', position: 'relative' }}>
              <h3 style={{ color: '#8391a2' }}>Team</h3>
            </li>
            <li>
              <NavLink to="/TeamMaster">
                <i className="bx bx-group" />
                <span>Team master</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/TeamPermission">
                <i className="bx bxs-lock-open" />
                <span>Team permission</span>
              </NavLink>
            </li>
          </>
          }
        </ul>
      </div>
    </>
  )
}

export default Sidebar