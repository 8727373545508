const initialState = {}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_USERS":
            return action.payload;

        case "REGISTER_USER":
            return [action.payload, ...state];

        case "UPDATE_USER":
            const originalState = [...state];
            const index = originalState.findIndex(x => x.EmployeeID === action.payload.EmployeeID);

            originalState[index] = {
                EmployeeID: action.payload.EmployeeID,
                CardNo: action.payload.CardNo,
                TypeID: action.payload.TypeID,
                UserName: action.payload.UserName,
                TeamID: action.payload.TeamID,
                PermissionID: action.payload.PermissionID,
                UseYN: action.payload.UseYN,
                ManagerID: action.payload.ManagerID,
                Email: action.payload.Email
            }

            return originalState;

        case "DELETE_USER":
            const users = state.filter(x => x.EmployeeID !== action.payload.EmployeeID);

            const employee = state.filter(x => x.EmployeeID === action.payload.EmployeeID
                && x.CardNo !== action.payload.CardNo
            );

            const modifiedObject = [...employee, ...users];
            return modifiedObject;
            
        default:
            return state;
    }
}

export default userReducer