import React from 'react'
import { Link } from 'react-router-dom';
import SignOutButton from './SignOutButton';
// import { BiUser, BiPalette,BiBell,BiLogOut } from "react-icons/bi";

function Header(props) {
    const changeTheme = e => {
        e.preventDefault()
        document.querySelector('.layout').classList.toggle('theme-mode-dark')
    }

    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <span className="logo" />
                </div>
                <div className="topRight">
                    <div className="topbarIconContainer">
                        <Link to="/NeedHelp"><span>Need Help ?</span></Link>
                    </div>

                    <div className="topbarIconContainer">
                        <i className="bx bx-user">
                            <span>{props.displayName}</span>
                        </i>
                    </div>

                    <div className="topbarIconContainer" id="darkmode-toggle" onClick={changeTheme}>
                        <i className="bx bx-palette" />
                        <span className="topIconBadge">
                            D
                        </span>
                    </div>
                    <div className="topbarIconContainer">
                        <i className="bx bx-bell" />
                        <span className="topIconBadge">2</span>
                    </div>
                    <div className="topbarIconContainer">
                        {/* <i className="bx bx-log-out">
                                <span>Logout</span>
                            </i> */}

                        <SignOutButton />
                    </div>

                    <img src="https://www.dupont.com/content/dam/dupont/global-assets/logos/DuPont_logo_140x55_rgb1.png" className="topAvatar" />
                </div>
            </div>
        </div>

    );
}

export default Header