const initialState = {}

const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TEAMS":
            return action.payload;

        case "CREATE_TEAM":
            return [action.payload, ...state];

        case "UPDATE_TEAM":
            const originalState = [...state];
            const index = originalState.findIndex(x => x.TeamID === action.payload.TeamID);

            originalState[index] = {
                TeamID: action.payload.TeamID,
                TeamName: action.payload.TeamName,
                ManagerID: action.payload.ManagerID,
                UseYN: action.payload.UseYN
            }

            return originalState;

        case "DELETE_TEAM":
            const temList = state.filter(x => x.TeamID !== action.payload)
            return temList;
        default:
            return state;
    }
}

export default teamReducer